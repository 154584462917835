var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-grid', {
    attrs: {
      "width": "100%",
      "template-columns": ['repeat(6, 1fr)'],
      "gap": ['6px'],
      "margin-bottom": "24px"
    }
  }, _vm._l(_vm.constants.steps, function (step) {
    return _c('c-box', {
      key: step.value,
      attrs: {
        "cursor": "pointer",
        "border-radius": "8px",
        "_hover": {
          backgroundColor: 'neutral.superLightGray'
        }
      },
      on: {
        "click": function click($event) {
          return _vm.onChangeMealTime(step.value);
        }
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "16px",
        "font-weight": "500",
        "color": _vm.getColor(step.value, 'text'),
        "text-align": "center",
        "margin-bottom": "12px"
      }
    }, [_vm._v(" " + _vm._s(step.label) + " ")]), _c('c-box', {
      attrs: {
        "width": "100%",
        "height": "8px",
        "border-radius": "20px",
        "background-color": _vm.getColor(step.value, 'background')
      }
    })], 1);
  }), 1), _c('c-flex', {
    attrs: {
      "width": "100%",
      "align-items": "center",
      "justify-content": "center",
      "position": "relative",
      "margin-bottom": "24px"
    }
  }, [_vm.activeStepIndex > 0 ? _c('c-button', {
    attrs: {
      "variant-color": "primary",
      "width": ['26px', '30px'],
      "min-width": ['26px', '30px'],
      "height": ['26px', '30px'],
      "text-align": "center",
      "margin": "0",
      "padding": "0",
      "border-radius": "100%",
      "position": "absolute",
      "left": "200px",
      "is-disabled": _vm.isLoading
    },
    on: {
      "click": _vm.onPrevious
    }
  }, [_c('c-box', {
    attrs: {
      "width": ['12px', '15px'],
      "height": ['12px', '15px']
    }
  }, [_c('inline-svg', {
    staticStyle: {
      "transform": "rotate(90deg)"
    },
    attrs: {
      "src": require('@/assets/icons/icon-arrow-down.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#FFF"
    }
  })], 1)], 1) : _vm._e(), _vm.activeStepIndex < _vm.constants.steps.length - 1 ? _c('c-button', {
    attrs: {
      "variant-color": "primary",
      "width": ['26px', '30px'],
      "min-width": ['26px', '30px'],
      "height": ['26px', '30px'],
      "text-align": "center",
      "margin": "0",
      "padding": "0",
      "border-radius": "100%",
      "position": "absolute",
      "right": "200px",
      "is-disabled": _vm.isLoading
    },
    on: {
      "click": _vm.onNext
    }
  }, [_c('c-box', {
    attrs: {
      "width": ['12px', '15px'],
      "height": ['12px', '15px']
    }
  }, [_c('inline-svg', {
    staticStyle: {
      "transform": "rotate(-90deg)"
    },
    attrs: {
      "src": require('@/assets/icons/icon-arrow-down.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#FFF"
    }
  })], 1)], 1) : _vm._e(), _c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-size": "28px",
      "font-weight": "600"
    }
  }, [_vm._v(" " + _vm._s(_vm.activeStepLabel) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }