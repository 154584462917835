var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.withMargin ? '' : 'no-margin',
    attrs: {
      "id": "container"
    }
  }, [_c('div', {
    attrs: {
      "id": "editor-".concat(_vm.id)
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }