var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', _vm._l(_vm.menus, function (item, i) {
    return _c('AccordionMealPlanMenu', {
      key: i,
      attrs: {
        "item": item,
        "is-disabled": _vm.isDisabled,
        "remaining-calories": _vm.remainingCalories
      },
      on: {
        "update:item": function updateItem(val) {
          return _vm.updateItem(i, val);
        }
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }