<template>
  <c-box>
    <AccordionMealPlanMenu
      v-for="(item, i) in menus"
      :key="i"
      :item="item"
      :is-disabled="isDisabled"
      :remaining-calories="remainingCalories"
      @update:item="val => updateItem(i, val)"
    />
  </c-box>
</template>

<script>
import AccordionMealPlanMenu from '@/components/meal-plan/_widgets/accordion-meal-plan-menu'

export default {
  components: {
    AccordionMealPlanMenu,
  },
  props: {
    menus: {
      type: Array,
      default: () => [],
    },
    remainingCalories: {
      type: [Number, null],
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:menus'],
  methods: {
    updateItem(index, data) {
      let menus = this.menus
      menus[index] = data
      this.$emit('update:menus', menus)
    },
  },
}
</script>

<style>

</style>