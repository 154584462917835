<template>
  <c-form-control
    :is-disabled="isDisabled"
    :is-required="isRequired"
    :is-invalid="isInvalid"
    :mb="isWithoutMarginBottom ? '0' : ['16px', '24px']"
    :is-read-only="isReadOnly"
    :w="fullWidth ? '100%' : 'inherit'"
  >
    <c-form-label
      :font-size="['14px', '16px']"
      :color="isDisabled ? '#888' : (isInvalid ? '#D32737' : (value ? '#008C81' : '#555'))"
      font-weight="400"
      font-family="Roboto"
      :opacity="isDisabled ? '1' : ''"
    >
      {{ label }}
      <c-box
        v-if="!isRequired"
        as="span"
        font-family="Roboto"
        font-weight="400"
        color="#C4C4C4"
        :font-size="['12px', '14px']"
      >
        (Optional)
      </c-box>
    </c-form-label>
    <vue-timepicker
      ref="formTime"
      v-model="modelValue"
      v-chakra="{
        'input.display-time': {
          p: '1rem !important',
          height: ['48px !important', '62px !important'],
          fontSize: ['16px !important', '18px !important'],
          fontFamily: 'Roboto',
          fontWeight: '500',
          borderRadius: '8px',
          border: '1px solid #888',
          boxShadow: '0 0 0 1px #888',
        },
        '.controls>*': {
          cursor: isDisabled ? 'not-allowed !important' : 'pointer',
          userSelect: isDisabled ? 'none !important' : 'all',
        },
        'input:disabled': {
          backgroundColor: '#F2F2F2 !important',
          cursor: 'not-allowed !important',
        },
      }"
      format="HH:mm"
      :minute-interval="1" 
      hour-label="Jam"
      minute-label="Menit"
      input-width="100%"
      :close-on-complete="true"
      :hide-clear-button="false" 
      drop-direction="up"
      container-id="formTime"
      :disabled="isDisabled"
      :input-class="['skip-error-style', isInvalid ? 'invalid' : '']"
      :color="isDisabled ? '#888888' : modelValue ? '#008C81' : '#555555'"
      :background-color="isDisabled ? 'neutral.superLightGray' : 'white'"
      fixed-dropdown-button 
      lazy
      :class="isDisabled ? 'disabled' : ''"
      @blur="onBlur"
    >
      <template #dropdownButton>
        <c-flex align="center">
          <inline-svg
            :src="require('@/assets/icons/icon-clock.svg')"
            height="30px"
            width="30px"
            fill="#888"
          />
        </c-flex>
      </template>
    </vue-timepicker>
    <c-form-helper-text
      v-if="isHelper"
      :font-size="['12px', '16px']"
      :font-weight="['400', '400']"
      d="flex"
      gap="8px"
      align-items="center"
      :color="helperTextColor"
    >
      <c-box
        display="flex"
        gap="8px"
        align-items="center"
        :cursor="hasHelperTextClick ? 'pointer' : 'auto'"
        @click="$emit('helper-text-click')"
      >
        <c-box
          v-if="helperTextLogo"
          :min-height="['13px', '16px']"
          :min-width="['13px', '16px']"
          :height="['13px', '16px']"
          :width="['13px', '16px']"
        >
          <inline-svg
            :src="helperTextLogo"
            height="100%"
            width="100%"
            :fill="helperTextColor"
          />
        </c-box>
        {{ helperText }}
      </c-box>
    </c-form-helper-text>

    <c-form-helper-text
      v-if="!isDataEmpty(invalidText) && isInvalid"
      :font-size="['12px', '16px']"
      :font-weight="['400', '400']"
      d="flex"
      flex-direction="column"
      gap="8px"
      color="#D32737"
    >
      <c-text
        v-for="(invalidTextValue, invalidTextKey) in invalidText_"
        :key="invalidTextKey"
        d="flex"
        align-items="center"
        gap="8px"
      >
        <c-box
          :min-height="['13px', '16px']"
          :min-width="['13px', '16px']"
          :height="['13px', '16px']"
          :width="['13px', '16px']"
        >
          <inline-svg
            :src="require('@/assets/icons/icon-circle-warn.svg')"
            height="100%"
            width="100%"
            fill="#D32737"
          />
        </c-box>
        {{ invalidTextValue }}
      </c-text>
    </c-form-helper-text>
  </c-form-control>
</template>

<script>
import { isDataEmpty } from '@/utils/is-data-empty'
import VueTimepicker from 'vue2-timepicker'
import _ from 'lodash'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  name: 'FormInput',
  components: {
    VueTimepicker,
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    invalidText: {
      type: [String, Object],
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      required: true,
    },
    isHelper: {
      type: Boolean,
      default: false,
    },
    helperText: {
      type: String,
      default: '',
    },
    isWithoutMarginBottom: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    helperTextType: {
      type: String,
      default: '',
    },
    helperTextColor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  computed: {
    invalidText_() {
      if (typeof this.invalidText === 'string') {
        return [this.invalidText]
      }
      return this.invalidText
    },
    helperTextLogo() {
      if (this.helperTextType === 'warn') {
        return require('@/assets/icons/icon-circle-warn.svg')
      }
      if (this.helperTextType === 'questionmark') {
        return require('@/assets/icons/icon-circle-questionmark.svg')
      }
      return ''
    },
    hasHelperTextClick() {
      return this.$listeners['helper-text-click']
    },
    modelValue: {
      get() {
        return this.value
      },
      set(val) {
        if (val.includes(':mm')) {
          const value = val.slice(0, 2) + ':00'
          this.$emit('input', value)
        } else { 
          this.$emit('input', val)
        }
      },
    },
  },
  watch: {
    isDisabled: {
      handler(val) {
        if (val) {
          this.$refs['formTime'].hour = ''
          this.$refs['formTime'].minute = ''
          this.$refs['formTime'].second = ''
          this.$refs['formTime'].apm = ''
        }
      },
    },
  },
  methods: {
    isDataEmpty,
    onBlur() {
      this.$emit('blur')
    },
  },
}
</script>

<style scoped>
::v-deep.vue__time-picker-dropdown, ::v-deep.vue__time-picker .dropdown {
  bottom: 4em !important;
}

::v-deep.vue__time-picker input.display-time {
  font-size: 18px !important;
  border-color: #888;
  color: inherit;
}

::v-deep.vue__time-picker input.invalid {
  box-shadow:  0 0 0 1px #e66673 !important;
  border: 1px solid #e66673 !important;
}

::v-deep.vue__time-picker .controls img,
::v-deep.vue__time-picker .controls svg {
  max-width: 35px !important;
  margin-right: 12px !important;
}

::v-deep.vue__time-picker.disabled .controls>* {
  pointer-events: none !important;
}

@media (min-width: 768px) {
  ::v-deep.vue__time-picker-dropdown, ::v-deep.vue__time-picker .dropdown {
    bottom: 4em !important;
  }
}
</style>
