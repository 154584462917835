<template>
  <c-box>
    <c-grid
      width="100%"
      :template-columns="['repeat(6, 1fr)']"
      :gap="['6px']"
      margin-bottom="24px"
    >
      <c-box
        v-for="step in constants.steps"
        :key="step.value"
        cursor="pointer"
        border-radius="8px"
        :_hover="{
          backgroundColor: 'neutral.superLightGray',
        }"
        @click="onChangeMealTime(step.value)"
      >
        <c-text
          font-size="16px"
          font-weight="500"
          :color="getColor(step.value, 'text')"
          text-align="center"
          margin-bottom="12px"
        >
          {{ step.label }}
        </c-text>
        <c-box 
          width="100%"
          height="8px"
          border-radius="20px"
          :background-color="getColor(step.value, 'background')"
        />
      </c-box>
    </c-grid>
    
    <c-flex
      width="100%"
      align-items="center"
      justify-content="center"
      position="relative"
      margin-bottom="24px"
    >
      <c-button
        v-if="activeStepIndex > 0"
        variant-color="primary"
        :width="['26px', '30px']"
        :min-width="['26px', '30px']"
        :height="['26px', '30px']"
        text-align="center"
        margin="0"
        padding="0"
        border-radius="100%"
        position="absolute"
        left="200px"
        :is-disabled="isLoading"
        @click="onPrevious"
      >
        <c-box
          :width="['12px', '15px']"
          :height="['12px', '15px']"
        >
          <inline-svg
            :src="require('@/assets/icons/icon-arrow-down.svg')"
            height="100%"
            width="100%"
            fill="#FFF"
            style="transform: rotate(90deg);"
          />
        </c-box>
      </c-button>
      <c-button
        v-if="activeStepIndex < (constants.steps.length - 1)"
        variant-color="primary"
        :width="['26px', '30px']"
        :min-width="['26px', '30px']"
        :height="['26px', '30px']"
        text-align="center"
        margin="0"
        padding="0"
        border-radius="100%"
        position="absolute"
        right="200px"
        :is-disabled="isLoading"
        @click="onNext"
      >
        <c-box
          :width="['12px', '15px']"
          :height="['12px', '15px']"
        >
          <inline-svg
            :src="require('@/assets/icons/icon-arrow-down.svg')"
            height="100%"
            width="100%"
            fill="#FFF"
            style="transform: rotate(-90deg);"
          />
        </c-box>
      </c-button>
      <c-text
        color="primary.400"
        font-size="28px"
        font-weight="600"
      >
        {{ activeStepLabel }}
      </c-text>
    </c-flex>
  </c-box>
</template>

<script>
import {
  tabsMealPlan,
  mealPlanSteps,
} from '@/constants/meal-plans'

export default {
  props: {
    activeStep: {
      type: String,
      default: '',
    },
    draft: {
      type: Array,
      default: () => [],
    },
    validation: {
      type: [Number, null],
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['save'],
  computed: {
    constants() {
      return {
        steps: mealPlanSteps,
        tabsMealPlan,
      }
    },
    activeStepIndex() {
      if (!this.activeStep) return -1
      return this.constants.steps.findIndex((it) => it.value === this.activeStep)
    },
    activeStepLabel() {
      if (this.activeStepIndex > -1) {
        return this.constants.steps[this.activeStepIndex]?.label
      }
      return ''
    },
  },
  methods: {
    getColor(key, type) {
      if (this.activeStep === key) {
        if (this.validation === 1) {
          return 'primary.400'
        }
        return 'danger.400'
      }

      const mealPlan = this.draft.find((it) => it.mealTime === key)
      if (mealPlan?.validation === 1) {
        return 'primary.400'
      } else if (mealPlan?.validation === -1) {
        return 'danger.400'
      } else {
        if (type === 'text') {
          return 'neutral.lightGray'
        }
        return 'neutral.superLightGray'
      }
    },
    async onPrevious() {
      await this.$emit('save')
      this.$router.push({
        name: this.$route.name,
        params: this.$route.params,
        query: {
          tab: this.constants.tabsMealPlan['meal-plan'],
          mealTime: this.constants.steps[this.activeStepIndex - 1]?.value,
        },
      })
    },
    async onNext() {
      await this.$emit('save')
      this.$router.push({
        name: this.$route.name,
        params: this.$route.params,
        query: {
          tab: this.constants.tabsMealPlan['meal-plan'],
          mealTime: this.constants.steps[this.activeStepIndex + 1]?.value,
        },
      })
    },
    async onChangeMealTime(step) {
      if (step !== this.activeStep) {
        await this.$emit('save')
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            tab: this.constants.tabsMealPlan['meal-plan'],
            mealTime: step,
          },
        })
      }
    },
  },
}
</script>

<style>

</style>